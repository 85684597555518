import React from "react";
import { Route } from "react-router";

const Login = React.lazy(() => import("./../screens/auth/login"));
const ResetPassword = React.lazy(
  () => import("./../screens/auth/ResetPassword")
);

// const Announcements = React.lazy(() => import("./../screens/announcements"));
const CustomerDashboard = React.lazy(
  () => import("./../screens/dashboard/CustomerDashboard")
);
const PrimDashboard = React.lazy(
  () => import("./../screens/dashboard/PrimDashboard")
);

const ActivityList = React.lazy(
  () => import("./../screens/activities/ActivityList")
);
const Profile = React.lazy(() => import("./../screens/user/Profile"));
const Users = React.lazy(() => import("./../screens/user/List"));
const Roles = React.lazy(() => import("./../screens/user/Roles"));
const Permissions = React.lazy(() => import("./../screens/user/Permissions"));
// const RolePages = React.lazy(() => import("./../screens/user/PageList"));

const AdminCustomers = React.lazy(
  () => import("./../screens/adminCustomers/List")
);

const Customers = React.lazy(() => import("./../screens/customers/List"));
const CustomerPassiveRequests = React.lazy(
  () => import("../screens/customers/passiveRequests/CustomerPassiveRequests")
);
const CustomerTransfer = React.lazy(
  () => import("./../screens/customers/transfer")
);
const CustomerProfile = React.lazy(
  () => import("./../screens/customers/profile")
);
const CustomerTypes = React.lazy(
  () => import("./../screens/customers/type/List")
);
const CustomerChannels = React.lazy(
  () => import("./../screens/customers/channels/List")
);
const CustomerStatus = React.lazy(
  () => import("./../screens/customers/status/List")
);
const PurchasePreferences = React.lazy(
  () => import("./../screens/customers/purchasePreferences/List")
);

const Offers = React.lazy(() => import("../screens/products/offer/List"));
const OfferPage = React.lazy(
  () => import("../screens/products/offer/OfferPage")
);

const ProductCard = React.lazy(
  () => import("./../screens/products/ProductCard")
);
const Products = React.lazy(() => import("./../screens/products/List"));
const ProductCategories = React.lazy(
  () => import("screens/products/categories/List")
);
const ProductTeams = React.lazy(() => import("screens/products/teams/List"));
const Suppliers = React.lazy(() => import("screens/products/suppliers/List"));

const Settings = React.lazy(() => import("screens/settings"));

//Mail
const MailTemplateList = React.lazy(() => import("screens/email/TemplateList"));

export const publicRoutes = [
  {
    path: "/",
    pageType: "systemcx",
    Content: AdminCustomers,
    route: Route,
  },
  {
    path: "/dashboard/customer",
    pageType: "dash_c",
    Content: CustomerDashboard,
    route: Route,
  },
  {
    path: "/dashboard/prim",
    pageType: "dash_p",
    Content: PrimDashboard,
    route: Route,
  },
  {
    path: "/customers",
    pageType: "customer",
    Content: Customers,
    route: Route,
  },
  {
    path: "/customers/passive-confirm",
    pageType: "customer",
    Content: CustomerPassiveRequests,
    route: Route,
  },
  {
    path: "/customer-transfer",
    pageType: "customer",
    Content: CustomerTransfer,
    route: Route,
  },
  {
    path: "/customers/detail/:id",
    pageType: "customer",
    Content: CustomerProfile,
    route: Route,
  },
  {
    path: "/customers/types",
    pageType: "customer",
    Content: CustomerTypes,
    route: Route,
  },
  {
    path: "/customers/purchase-preferences",
    pageType: "customer",
    Content: PurchasePreferences,
    route: Route,
  },
  {
    path: "/customers/status",
    pageType: "customer",
    Content: CustomerStatus,
    route: Route,
  },
  {
    path: "/customers/channels",
    pageType: "customer",
    Content: CustomerChannels,
    route: Route,
  },
  {
    path: "/profile",
    pageType: "profile",
    Content: Profile,
    route: Route,
  },
  {
    path: "/users",
    pageType: "user",
    Content: Users,
    route: Route,
  },
  {
    path: "/users/detail/:id",
    pageType: "user",
    Content: Profile,
    route: Route,
  },
  {
    path: "/activities",
    pageType: "role",
    Content: ActivityList,
    route: Route,
  },
  {
    path: "/roles",
    pageType: "role",
    Content: Roles,
    route: Route,
  },
  // {
  //   path: "/roles/pages/:id",
  //   pageType: "role",
  //   Content: RolePages,
  //   route: Route,
  // },
  {
    path: "/roles/permissions/:id",
    pageType: "role",
    Content: Permissions,
  },
  {
    path: "/offers",
    pageType: "offer",
    Content: Offers,
  },
  {
    path: "/offers/:id",
    pageType: "offer",
    Content: OfferPage,
  },
  {
    path: "/products",
    pageType: "product",
    Content: Products,
  },
  {
    path: "/products/card/:id",
    pageType: "product",
    Content: ProductCard,
  },
  {
    path: "/product-categories",
    pageType: "product",
    Content: ProductCategories,
  },
  {
    path: "/product-teams",
    pageType: "product",
    Content: ProductTeams,
  },
  {
    path: "/suppliers",
    pageType: "product",
    Content: Suppliers,
  },
  {
    path: "/settings/:page/:tab",
    pageType: "settings",
    Content: Settings,
  },

  //Email Screens
  {
    path: "/email/templates",
    pageType: "mail_t",
    Content: MailTemplateList,
  },

  {
    path: "/set-password/:userId/:token",
    Content: ResetPassword,
    pageType: "user",
  },
];

export const authRoutes = [
  {
    path: "/",
    Content: Login,
    route: Route,
  },
  {
    path: "/login",
    Content: Login,
    route: Route,
  },
  {
    path: "/set-password/:userId/:token",
    Content: ResetPassword,
    route: Route,
  },
];
