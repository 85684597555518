export function getSidebarConfigurations(perms, customerId) {
  let permissions = {};
  for (const key in perms) {
    if (perms[key].canReach) {
      permissions[key] = perms[key];
    }
  }
  const items = [
    {
      permission: permissions.systemcx,
      label: "Sistem Ayarları",
      submenuOpen: true,
      showSubRoute: false,
      submenuHdr: "Inventory",
      submenuItems: [
        {
          label: "CRM Müşteriler",
          link: "/",
          icon: "ti ti-building-community",
          showSubRoute: false,
          submenu: false,
        },
      ],
    },

    // ---- Main Menu Start ----
    {
      permission:
        permissions.dash_c || permissions.dash_p || permissions.announce,
      label: "Ana Menü",
      submenuOpen: true,
      showSubRoute: true,
      submenuHdr: "board",
      submenuItems: [
        {
          permission: permissions.dash_c || permissions.dash_p,
          label: "Paneller",
          icon: "ti ti-layout-2",
          submenu: true,
          showSubRoute: true,
          submenuItems: [
            {
              permission: true,
              label: "Müşteri Paneli",
              link: "/dashboard/customer",
              showSubRoute: false,
            },
            {
              permission: true,
              label: "Prim Paneli",
              link: "/dashboard/prim",
              showSubRoute: false,
            },
          ],
        },
        {
          permission: permissions.announce,
          label: "Duyurular",
          link: "/",
          icon: "bi bi-broadcast",
          showSubRoute: false,
          submenu: false,
          badge: 2,
        },
      ],
    },
    // ---- Main Menu end ----

    // ---- Customer Menü Start ----
    {
      permission: permissions.customer,
      label: "Müşteri Yönetimi",
      submenuOpen: true,
      showSubRoute: true,
      submenuHdr: "Inventory",
      submenuItems: [
        {
          permission: true,
          label: "Müşteriler",
          link: "/customers",
          icon: "ti ti-building-community",
          showSubRoute: false,
          submenu: false,
        },
        {
          permission: permissions.customer?.permissions.psv,
          label: "Pasif Onayı",
          link: "/customers/passive-confirm",
          icon: "ti ti-check",
          showSubRoute: false,
          submenu: false,
        },
        // {
        //   permission: permissions.customer?.permissions?.trf,
        //   label: "Toplu Atama",
        //   link: "/customer-transfer",
        //   icon: "ti ti-arrows-horizontal",
        //   showSubRoute: false,
        //   submenu: false,
        // },
        {
          permission: true,
          label: "Müşteri Tanımlamaları",
          icon: "ti ti-brand-airtable",
          submenu: true,
          showSubRoute: true,
          submenuItems: [
            {
              permission: customerId !== "spabewa",
              label: "Satın Alma Tercihleri",
              link: "/customers/purchase-preferences",
              showSubRoute: false,
            },
            {
              permission: customerId !== "spabewa",
              label: "Müşteri Tipleri",
              link: "/customers/types",
              showSubRoute: false,
            },
            {
              permission: customerId !== "spabewa",
              label: "Kanallar",
              link: "/customers/channels",
              showSubRoute: false,
            },
            {
              permission: true,
              label: "Durumlar",
              link: "/customers/status",
              showSubRoute: false,
            },
          ],
        },
      ],
    },
    // ---- Customer Menü End ----

    // ---- Product Menü Start ----
    {
      permission: permissions.product || permissions.offer,
      label: "Ürün Yönetimi",
      submenuOpen: true,
      showSubRoute: false,
      submenuHdr: "Main",
      submenuItems: [
        {
          permission: permissions.product,
          label: "Ürünler",
          icon: "ti ti-layout-2",
          link: "/products",
          submenu: false,
          showSubRoute: false,
        },
        {
          permission: permissions.offer,
          label: "Teklifler",
          icon: "bi bi-cart3",
          link: "/offers",
          submenu: false,
          showSubRoute: false,
        },
        {
          permission: permissions.product,
          label: "Ürün Tanımlamaları",
          icon: "ti ti-brand-airtable",
          submenu: true,
          showSubRoute: false,
          submenuItems: [
            {
              permission: true,
              label: "Ürün Kategorileri",
              link: "/product-categories",
              showSubRoute: false,
            },
            {
              permission: true,
              label: "Takımlar",
              link: "/product-teams",
              showSubRoute: false,
            },
            {
              permission: true,
              label: "Tedariçiler",
              link: "/suppliers",
              showSubRoute: false,
            },
          ],
        },
      ],
    },
    // ---- Product Menü End ----

    // ---- User Menü Start ----
    {
      permission: permissions.user || permissions.role,
      label: "Kullanıcı Yönetimi",
      submenuOpen: true,
      submenuHdr: "Sales",
      submenu: false,
      showSubRoute: false,
      submenuItems: [
        {
          permission: permissions.user,
          label: "Kullanıcılar",
          link: "/users",
          icon: "ti ti-file-invoice",
          showSubRoute: false,
          submenu: false,
        },
        {
          permission: permissions.role,
          label: "Rol ve İzinler",
          link: "/roles",
          icon: "ti ti-navigation-cog",
          showSubRoute: false,
          submenu: false,
        },
      ],
    },
    // ---- User Menü End ----

    // ---- Mail Menü Start ----
    // {
    //   permission: permissions.user || permissions.role,
    //   label: "E-Posta",
    //   submenuOpen: true,
    //   submenuHdr: "Sales",
    //   submenu: false,
    //   showSubRoute: false,
    //   submenuItems: [
    //     {
    //       permission: permissions.user,
    //       label: "Şablonlar",
    //       link: "/email/templates",
    //       icon: "ti ti-mail",
    //       showSubRoute: false,
    //       submenu: false,
    //     },
    //   ],
    // },
    {
      permission: false,
      label: "Diğer",
      submenuOpen: true,
      submenuHdr: "Sales",
      submenu: false,
      showSubRoute: false,
      submenuItems: [
        {
          permission: true,
          label: "Aktiviteler",
          link: "/activities",
          icon: "ti ti-activity-heartbeat",
          showSubRoute: false,
          submenu: false,
        },
      ],
    },
    // ---- User Menü End ----

    // ---- Settings Menü Start ----
    {
      permission:
        permissions.settings ||
        permissions.com_settings ||
        permissions.bank_account ||
        permissions.exchange,
      label: "Ayarlar",
      submenuOpen: true,
      submenuHdr: "Sales",
      submenu: false,
      showSubRoute: false,
      submenuItems: [
        {
          permission: permissions.settings || permissions.comp_settings,
          label: "Genel Ayarlar",
          icon: "ti ti-brand-airtable",
          submenu: true,
          showSubRoute: true,
          submenuItems: [
            {
              permission: permissions.comp_settings,
              label: "Şirket Ayarları",
              link: "/settings/general/company",
              showSubRoute: false,
            },
            {
              permission: permissions.comp_settings,
              label: "Sosyal Medyalar",
              link: "/settings/general/socials",
              showSubRoute: false,
            },
            {
              permission: permissions.comp_settings,
              label: "Web Siteleri",
              link: "/settings/general/websites",
              showSubRoute: false,
            },
            {
              permission: permissions.comp_settings,
              label: "Kullanılan Diller",
              link: "/settings/general/languages",
              showSubRoute: false,
            },
            {
              permission: permissions.settings,
              label: "Tema Ayarları",
              link: "/settings/general/theme",
              showSubRoute: false,
            },
          ],
        },
        {
          permission:
            permissions.bank_account ||
            permissions.exchange ||
            permissions.settings,
          label: "Finans Ayarları",
          icon: "ti ti-brand-airtable",
          submenu: true,
          showSubRoute: true,
          submenuItems: [
            {
              permission: permissions.bank_account,
              label: "Banka Hesapları",
              link: "/settings/finance/bank-accounts",
              showSubRoute: false,
            },
            {
              permission: permissions.settings,
              label: "Prim Ayarları",
              link: "/settings/finance/prim",
              showSubRoute: false,
            },
            {
              permission: permissions.exchange,
              label: "Kur Ayarları",
              link: "/settings/finance/exchange-rates",
              showSubRoute: false,
            },
          ],
        },
        {
          permission:
            permissions.bank_account ||
            permissions.exchange ||
            permissions.settings,
          label: "Sistem Ayarları",
          icon: "ti ti-device-laptop",
          submenu: true,
          showSubRoute: true,
          submenuItems: [
            {
              permission: permissions.settings,
              label: "E-Posta Ayarları",
              link: "/settings/system/email",
              showSubRoute: false,
            },
            {
              permission: permissions.settings,
              label: "Sms Ayarları",
              link: "/settings/system/sms",
              showSubRoute: false,
            },
          ],
        },
      ],
    },
    // ---- Settings Menü End ----
  ];

  return items;
}
